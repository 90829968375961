import React from 'react';
import { AreasInfo, AreasCards } from './components';
import * as styles from './Areas.module.scss';

const Areas = () => {
  return (
    <article className={styles.container_areas}>
      <AreasCards />
      <AreasInfo />
    </article>
  );
};
export default Areas;
