// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_areas_info = "AreasInfo-module__container_areas_info___ux5T-";
export var container_areas_info__container_offer = "AreasInfo-module__container_areas_info__container_offer___t01F-";
export var container_areas_info__content = "AreasInfo-module__container_areas_info__content___4qCWI";
export var container_areas_info__content_offer = "AreasInfo-module__container_areas_info__content_offer___AEU1m";
export var container_areas_info__img = "AreasInfo-module__container_areas_info__img___i-0cm";
export var container_areas_info__subtitle = "AreasInfo-module__container_areas_info__subtitle___ilhqG";
export var container_areas_info__title = "AreasInfo-module__container_areas_info__title___0qGgI";
export var container_areas_info__title_agency = "AreasInfo-module__container_areas_info__title_agency___QXhdL";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";